<template>
  <input type="password" class="form-control" :class="{ 'is-invalid': hasError }" :id="inputId" :aria-describedby="helpTextId" :placeholder="placeholder" :value="value" @input="handleChange">
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ]
}
</script>
